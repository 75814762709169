export const team_mode = {
  create_channel: "Kanal anlegen",
  share_document: "Dokument teilen",
  subscribe_channel: "Kanal abonnieren",
  manage_channels: "Kanal-Verwaltung",
  subscribers: "Abonnenten",
  settings: "Einstellungen",
  created_at: "Erstellt am",
  shared_content: "Geteilte Inhalte",
  channel_data: "Kanal-Daten",
  subscription: "Abonniert",
  broadcast_channels: "Broadcast-Kanäl",
  owned_channels: "Eigene Kanäle",

  join_channel_dialog: {
    text: "Einem Broadcast-Kanal beitreten, um die geteilten Inhalte direkt an der passenden Norm einzusehen.",
    user_name_label: "Benutzername*",
    channel_label: "Kanal*",
    password_label: "Passwort*",
    channel_color_help_text: "Icon für Kanal-Inhalte auswählen",
    user_not_found: "Benutzer nicht gefunden.",
  },

  share_channel_dialog: {
    share_in_channel: "In Kanal teilen",
  },

  create_channel_dialog: {
    channel_name: "Channelname",
    channel_name_help_text:
      "Der Channelname darf max. 20 Zeichen enthalten (z.B. „BGB AT WS24/25“). Der Benutzername (z.B. Prof-Musterfrau) sollte nicht enthalten sein. Dieser wird ohnehin dazu angezeigt.",
    password_protected_help_text: "Passwort erforderlich?",
    password: "Passwort",
    password_repeat: "Passwort (wiederholen)",
    create_channel: "Kanal anlegen",
    download_allowed_help_text: "Download erlaubt?",
  },

  manage_channel_dialog: {
    own_broadcast_channels: "Eigene Broadcast-Channels",
    external_broadcast_channels: "Fremde Broadcast-Channels",
    created_at: "Erstellt am",
    updated_at: "Aktualisiert am",
    subscribers: "Abonnenten",
    schemas: "Schemata",
    download: "Download",
    password: "Passwort",
    shared_content: "Geteilte Inhalte",
    access_settings: "Zugriffseinstellungen",
    owner: "Besitzer",
    joined_at: "Beigetreten am",
    allowed: "Erlaubt",
    not_allowed: "Nicht erlaubt",
    not_necessary: "Nicht erforderlich",
    necessary: "********",
    unsubscribe: "Abbestellen",
    delete: "Löschen",
    delete_channel: "Channel löschen",
    confirm_cancellation: "Um ihre Abmeldung zu bestätigen, klicken sie hier:",
    deletion_warning:
      "Auch nach Löschen des Channels bleiben die geteilten Inhalte in deinem Account erhalten. Sie werden aber nicht mehr in den Accounts der Abonnenten angezeigt.",
    confirm_deletion_password:
      "Bestätige das Löschen des Channels durch Eingabe deines Passworts:",
    confirm_deletion: "Channel löschen:",
  },
};
